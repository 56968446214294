const HomeView = {
  namespace: 'home',
  beforeLeave () {
    /* Reset vertical scroll */
    document.documentElement.style.overflowY = '';
    /* Reset original colors */
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('--text-color', '#010101');
    bodyStyles.setProperty('--bg-color', '#FFFFFF');
  }
};

export default HomeView;
