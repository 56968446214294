/**
* Exhibition Transition
*/

const ExhibitionTransition = {
  name: 'exhibition-transition',
  to: {
    namespace: [
      'exhibition'
    ]
  },
  once ({ next }) {
    initExhibition(next);
  },
  enter ({ next }) {
    initExhibition(next);
  }
};

const initExhibition = (next) => {
  /* Set background color */
  document.body.style.transition = 'none';
  document.body.style.backgroundColor = 'var(--bg-color)';
  /* Load content */
  const currentContainer = next.container;
  const dynamicContent = currentContainer.querySelector('.dynamic-content');
  setTimeout(() => dynamicContent.classList.add('enter'), 0);
};

export { ExhibitionTransition };
