/**
* Home Once Transition
*/

const bodyStyles = document.body.style;

const HomeOnceTransition = {
  name: 'home-once-transition',
  to: {
    namespace: [
      'home'
    ]
  },
  once ({ next }) {
    /* Load page content */
    const dynamicContent = next.container.querySelector('.dynamic-content');
    dynamicContent.classList.add('enter');

    /* Initialize Featured News */
    if (next.container.querySelector('.news')) {
      const featuredNews = next.container.querySelector('.news');
      const closeButton = featuredNews.querySelector('.news-close');
      /* Initialize the animation */
      featuredNews.classList.add('is-active');
      /* Disable vertical scroll */
      document.documentElement.style.overflowY = 'hidden';
      /* Blur background and disable click on it */
      dynamicContent.classList.add('not-focus');
      dynamicContent.style.pointerEvents = 'none';

      /* Get Featured Exhibition colors */
      const colorsTemplate = next.container.querySelector('.exhibition-colors');
      const textColor = colorsTemplate.getAttribute('data-text-color');
      const backgroundColor = colorsTemplate.getAttribute('data-background-color');
      bodyStyles.setProperty('--text-color', textColor);
      bodyStyles.setProperty('--bg-color', backgroundColor);

      /* Display background image when hovering Learn More button */
      const learnMoreButton = next.container.querySelector('.news-learnmore');
      const imageOverlay = next.container.querySelector('.news-image-overlay img');
      learnMoreButton.addEventListener('mouseenter', function () {
        imageOverlay.classList.add('is-visible');
      });

      learnMoreButton.addEventListener('mouseleave', function () {
        imageOverlay.classList.remove('is-visible');
      });

      /* Close the Featured News when clicking the Close Button */
      closeButton.addEventListener('click', function () {
        closeFeaturedNews(next);
      });

      /* Close the Featured News when clicking outside it */
      next.container.addEventListener('click', function (event) {
        const isClickInside = featuredNews.contains(event.target);
        if (!isClickInside) {
          closeFeaturedNews(next);
        }
      });

      /* Close the Featured News when pressing ESC key */
      document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.key === 'Escape') {
          closeFeaturedNews(next);
        }
      };
    }
  }
};

const closeFeaturedNews = (next) => {
  const dynamicContent = next.container.querySelector('.dynamic-content');
  const featuredNews = next.container.querySelector('.news');
  /* Close Featured News */
  featuredNews.classList.remove('is-active');
  /* Reset vertical scroll */
  document.documentElement.style.overflowY = '';
  /* Reset original colors */
  setTimeout(() => {
    bodyStyles.setProperty('--text-color', '#010101');
    bodyStyles.setProperty('--bg-color', '#FFFFFF');
    dynamicContent.style.pointerEvents = '';
    dynamicContent.classList.remove('not-focus');
  }, 350);
};

export { HomeOnceTransition };
