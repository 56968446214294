import Flickity from 'flickity';

const bodyStyles = document.body.style;

const ExhibitionView = {
  namespace: 'exhibition',
  beforeEnter ({ next }) {
    const colorsTemplate = next.container.querySelector('.exhibition-colors');
    const textColor = colorsTemplate.getAttribute('data-text-color');
    const backgroundColor = colorsTemplate.getAttribute('data-background-color');
    bodyStyles.setProperty('--text-color', textColor);
    bodyStyles.setProperty('--bg-color', backgroundColor);

    const galleryElement = next.container.querySelector('.gallery');

    if (galleryElement) {
      const gallery = new Flickity(galleryElement, {
        cellSelector: '.gallery-cell',
        wrapAround: true,
        pageDots: false,
        prevNextButtons: false,
        lazyLoad: true,

        /* Fix a height issue on loading */
        on: {
          ready: function () {
            setTimeout(() => {
              gallery.resize();
            }, 100);
          }
        }
      });

      /* Initialize gallery's counter */
      const counterCurrentCell = next.container.querySelector('.gallery-controls-counter-current');
      const counterTotalCells = next.container.querySelector('.gallery-controls-counter-total');
      if (counterTotalCells) counterTotalCells.innerText = gallery.cells.length;

      /* Initialize gallery's caption */
      let imageCaption = next.container.querySelector('template[data-caption').getAttribute('data-caption');
      const imageCaptionContainer = next.container.querySelector('.gallery-controls-caption');
      if (imageCaption) imageCaptionContainer.innerHTML = imageCaption;

      /* Slide gallery when clicking Next button */
      const nextButton = next.container.querySelector('.gallery-controls-next');
      if (nextButton) {
        nextButton.addEventListener('click', function () {
          gallery.next(true);
        });
      }

      /* Update counter and caption when sliding images */
      gallery.on('change', function (index) {
        counterCurrentCell.innerText = index + 1;
        imageCaption = gallery.selectedElement.querySelector('template[data-caption').getAttribute('data-caption');
        imageCaptionContainer.innerHTML = imageCaption;
      });
    }
  },
  afterLeave () {
    bodyStyles.setProperty('--text-color', '#010101');
    bodyStyles.setProperty('--bg-color', '#FFFFFF');
  }
};

export default ExhibitionView;
