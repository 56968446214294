/**
 * © Antoine Puel — 2020
 */

/**
 * Import dependencies
 */
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import lazySizes from 'lazysizes';

/**
 * Import Transitions
 */
import { DefaultTransition } from './js/Transitions/Default';
import { HomeOnceTransition } from './js/Transitions/HomeOnce';
import { HomeToExhibition } from './js/Transitions/HomeToExhibition';
import { ExhibitionTransition } from './js/Transitions/Exhibition';

/**
 * Import Views
 */
import ExhibitionView from './js/Views/Exhibition';
import HomeView from './js/Views/Home';

/**
 * LazySizes Options
 */
lazySizes.cfg.lazyClass = 'lazy';

/**
 * Barba Options
 */
barba.use(barbaPrefetch);

barba.hooks.beforeEnter(() => {
  window.scrollTo(0, 0);
});

barba.init({
  transitions: [
    DefaultTransition,
    HomeOnceTransition,
    HomeToExhibition,
    ExhibitionTransition
  ],
  views: [
    ExhibitionView,
    HomeView
  ]
});
