/**
* Default Transition
*/

const DefaultTransition = {
  name: 'default-transition',
  once ({ next }) {
    // Play the fading animation at first page load
    const currentContainer = next.container;
    const dynamicContent = currentContainer.querySelector('.dynamic-content');
    dynamicContent.classList.add('enter');
  },
  enter ({ next }) {
    const currentContainer = next.container;
    const dynamicContent = currentContainer.querySelector('.dynamic-content');
    // Wait one frame before adding the class
    setTimeout(() => dynamicContent.classList.add('enter'), 0);
  }
};

export { DefaultTransition };
