/**
* From Home to Exhibition
*/

const HomeToExhibition = {
  name: 'from-home-to-exhibition',
  from: {
    namespace: [
      'home'
    ]
  },
  to: {
    namespace: [
      'exhibition'
    ]
  },
  enter ({ next }) {
    const transition = next.container.querySelector('.news-transition');
    transition.classList.add('is-active');
    setTimeout(() => {
      document.body.style.backgroundColor = 'var(--bg-color)';
    }, 250);

    const currentContainer = next.container;
    const dynamicContent = currentContainer.querySelector('.dynamic-content');
    dynamicContent.classList.add('home-to-exhibition');
    dynamicContent.classList.add('enter');
  }
};

export { HomeToExhibition };
